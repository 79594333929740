import { useDraggable } from "@dnd-kit/core";
import classNames from "classnames";
import { useEffect } from "react";

import { useToastHandlers } from "../ToastHub";
import { ToastPortal } from "../ToastPortal";
import styles from "./ToastBase.module.scss";
import { Toast } from "./types";

interface ToastBaseProps {
  toast: Toast;
  photoOrIconSlot?: React.ReactNode;
  rightSideSlot?: React.ReactNode;
  className?: string;
}

const ToastBase = ({
  toast,
  photoOrIconSlot,
  rightSideSlot,
  className,
}: ToastBaseProps) => {
  const { removeToast } = useToastHandlers();

  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: toast.id,
  });

  useEffect(() => {
    const timeOutId = window.setTimeout(() => {
      removeToast(toast.id);
    }, 3300);

    return () => window.clearTimeout(timeOutId);
  }, [toast.id]);

  const onClick = toast.clickHandler
    ? () => {
        // левая часть не нужна, но линтер тупой
        toast.clickHandler && toast.clickHandler();
        removeToast(toast.id);
      }
    : undefined;

  return (
    <ToastPortal>
      <div
        className={classNames(styles.root, className)}
        onClick={onClick}
        ref={setNodeRef}
        {...listeners}
        {...attributes}
        style={{
          translate: transform?.x && transform?.x > 0 ? transform?.x : 0,
        }}
      >
        {photoOrIconSlot}
        <div className={styles.textContainer}>
          <p className={styles.textTitleMessage}>{toast.title}</p>
          <p className={styles.text}>{toast.text}</p>
        </div>
        {rightSideSlot}
      </div>
    </ToastPortal>
  );
};

export default ToastBase;
