import classNames from "classnames";

import { useLockBodyScrollModified } from "@/hooks/useLockBodyScrollModified";
import { useModalAppearing } from "@/hooks/useModalAppearing";

import { ModalPortal } from "../ModalPortal";
import styles from "./BaseConfirmModal.module.scss";

interface BaseConfirmModalProps {
  isOpen: boolean;
  close?(): void;
  onSubmitButtonClickHandler?(): void;
  title?: string;
  text?: string;
  submitText?: string;
  cancelText?: string;
}

const BaseConfirmModal = ({
  isOpen,
  close,
  onSubmitButtonClickHandler,
  title,
  text,
  submitText,
  cancelText,
}: BaseConfirmModalProps) => {
  const { isVisible, mounting, hiding } = useModalAppearing({ isOpen });
  useLockBodyScrollModified(isOpen);

  const confirmHandler = () => {
    onSubmitButtonClickHandler && onSubmitButtonClickHandler();
    close && close();
  };

  if (!isVisible) return null;

  return (
    <ModalPortal>
      <div
        className={classNames(styles.root, {
          [styles.show]: mounting,
          [styles.hide]: hiding,
        })}
      >
        {(title || text) && (
          <div className={styles.alert__window}>
            <h3 className={styles.title}>{title}</h3>
            <p className={styles.text}>{text}</p>
            <div className={styles.buttonsContainer}>
              <button
                className={classNames(styles.button, styles.submit)}
                onClick={confirmHandler}
              >
                {submitText}
              </button>
              <button
                className={classNames(styles.button, styles.cancel)}
                onClick={close}
              >
                {cancelText}
              </button>
            </div>
          </div>
        )}
      </div>
    </ModalPortal>
  );
};

export default BaseConfirmModal;
