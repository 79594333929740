import { ConfirmationHub } from "../ConfirmaitionHub/ConfirmationHub";

const GlobalModalContainer = () => {
  return (
    <>
      <ConfirmationHub />
    </>
  );
};

export default GlobalModalContainer;
