import { DndContext, DragMoveEvent } from "@dnd-kit/core";
import { useRecoilValue } from "recoil";

import { useDndSensors } from "@/hooks/useDndSensors";

import { ToastBase } from "../ToastBase";
import { ToastIconMessage } from "../ToastIconMessage";
import { ToastProduct } from "../ToastProduct";
import { toastHubState } from "./toastHubState";
import { useToastHandlers } from "./useToastHandlers";

const ToastHub = () => {
  const toasts = useRecoilValue(toastHubState);
  const { removeToast } = useToastHandlers();
  const sensors = useDndSensors();

  const onDragMove = (event: DragMoveEvent) => {
    event.delta.x > 100 && removeToast(event.active.id);
  };

  return (
    <DndContext onDragMove={onDragMove} sensors={sensors}>
      {toasts.map((toast) => {
        switch (toast.variant) {
          case "base":
            return <ToastBase key={toast.id} toast={toast} />;
          case "product":
            return <ToastProduct key={toast.id} toast={toast} />;
          case "iconMessage":
            return <ToastIconMessage key={toast.id} toast={toast} />;
          default:
            return <ToastBase key={toast.id} toast={toast} />;
        }
      })}
    </DndContext>
  );
};

export default ToastHub;
