import { ToastBase } from "../ToastBase";
import { Toast } from "../ToastBase/types";
import styles from "./ToastProduct.module.scss";

interface ToastProductProps {
  toast: Toast;
}

const ToastProduct = ({ toast }: ToastProductProps) => {
  return (
    <ToastBase
      className={styles.root}
      toast={toast}
      photoOrIconSlot={
        toast.photo && (
          <img className={styles.photo} src={toast.photo} alt="product photo" />
        )
      }
      rightSideSlot={
        Boolean(toast.cartCount) && (
          <div className={styles.cartContainer}>
            <img
              className={styles.veshalka}
              src="/static/img/icons-v6/cart.svg"
              alt="cart icon"
            />
            <p className={styles.count}>{toast.cartCount}</p>
          </div>
        )
      }
    />
  );
};

export default ToastProduct;
