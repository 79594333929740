import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";

import { Toast } from "../ToastBase/types";
import { toastHubState } from "./toastHubState";

const TOAST_HEIGHT_WITH_GAP = 68;

export function useToastHandlers() {
  const [toasts, setToasts] = useRecoilState(toastHubState);
  const [cooldown, setCooldown] = useState<number>(0);

  useEffect(() => {
    if (!cooldown) return;

    const timeOutId = window.setTimeout(() => {
      setCooldown(0);
    }, cooldown);

    return () => {
      window.clearTimeout(timeOutId);
    };
  }, [cooldown]);

  const addToast = async ({
    photo,
    title,
    text,
    cartCount,
    clickHandler,
    variant = "base",
    delay,
  }: Omit<Toast, "id">) => {
    if (cooldown) return;

    const isFirstToast = toasts.length === 0;

    !isFirstToast && (await shiftToastContainer());

    setToasts((state) => [
      ...state,
      {
        id: Math.random().toString(),
        photo,
        title,
        text,
        cartCount: cartCount ? cartCount : undefined,
        clickHandler,
        variant,
      },
    ]);

    if (delay) {
      setCooldown(delay);
    }
  };

  const removeToast = (id: string) => {
    setToasts((state) => state.filter((toast) => toast.id !== id));
  };

  return { addToast, removeToast };
}

function shiftToastContainer() {
  const toastContainer = document.getElementById("toast-container") as Element;

  const animation = toastContainer.animate(
    [
      { transform: "translateY(0)" },
      { transform: `translateY(${TOAST_HEIGHT_WITH_GAP}px)` },
    ],
    {
      duration: 150,
      easing: "ease-out",
    }
  );

  animation.startTime = document.timeline.currentTime;
  return animation.finished;
}
