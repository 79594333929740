import classNames from "classnames";

import { useModalAppearing } from "@/hooks/useModalAppearing";

import { AnimatedLogo } from "../AnimatedLogo";
import styles from "./FullScreenLoader.module.scss";

interface FullScreenLoaderProps {
  isOpen: boolean;
}

const FullScreenLoader = ({ isOpen }: FullScreenLoaderProps) => {
  const { isVisible, hiding } = useModalAppearing({
    isOpen,
    timeout: 300,
  });

  if (!isVisible) return null;

  return (
    <div
      className={classNames(styles.root, {
        [styles.hide]: hiding,
      })}
    >
      <AnimatedLogo />
    </div>
  );
};

export default FullScreenLoader;
