import { MouseSensor, TouchSensor, useSensor, useSensors } from "@dnd-kit/core";

const sensorsOptions = {
  activationConstraint: {
    distance: 10,
  },
};

export const useDndSensors = () => {
  return useSensors(
    useSensor(MouseSensor, sensorsOptions),
    useSensor(TouchSensor, sensorsOptions)
  );
};
