import { atom } from "recoil";

interface confirmation {
  title?: string;
  text?: string;
  submitText?: string;
  cancelText?: string;
  onSubmit(): void;
  onCancel(): void;
}

export const confirmationState = atom<confirmation | undefined>({
  key: "confirmationState",
  default: undefined,
});
