import axios from "axios";

import AuthApi from "@/api/AuthApi";
import { API_URL } from "@/config";

const restrictedRoutes = ["cart", "profile", "orders"];

export const setAxiosConfig = (token: string) => {
  axios.defaults.baseURL = API_URL;
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      const status = error?.response?.status || 0;
      const url = error?.response?.config?.url || "";
      const isRestrictedRoute = restrictedRoutes.some(
        (item) => url.startsWith(item) || url.startsWith(`/${item}`)
      );
      if (
        typeof window !== "undefined" &&
        [401, 403].includes(status) &&
        isRestrictedRoute
      ) {
        AuthApi.logout();
        return window.location.reload();
      }
      return Promise.reject(error);
    }
  );
};
