import styles from "./AnimatedLogo.module.scss";

const AnimatedLogo = () => {
  return (
    <svg
      className={styles.logo}
      width="78"
      height="12"
      viewBox="0 0 78 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient id="Gradient1">
          <stop offset="0%" stopColor="#B6B2B0" />
          <stop offset="33%" stopColor="#676560" />
          <stop offset="67%" stopColor="#B6B2B0" />
          <stop offset="100%" stopColor="#676560" />,
        </linearGradient>
        <mask id="Mask">
          <rect
            className={styles.maskElem}
            x="-234"
            y="0"
            width="351"
            height="18"
            fill="url(#Gradient1)"
          />
        </mask>
      </defs>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0093 12H12.9994H0L4.21887 0.00334918H9.55837L6.18987 9.56716H13.0752L16.447 0H21.681L19.921 4.89249L27.1491 0H38.3554L40.8868 7.70735H40.9856C41.4004 5.98689 41.9211 4.29464 42.5446 2.64061L43.4708 0.00334918H48.3159L44.0872 12H37.9237L35.3264 3.81681H35.2671C34.7811 5.67666 34.2014 7.50985 33.5301 9.30913L32.5842 11.9899H23.9816L21.1273 6.96342L18.7871 8.24686L17.4687 12H13.0093ZM28.395 10.1201L31.5591 1.13935L25.2275 4.95616L28.395 10.1201ZM62.2483 12H57.4131L45.0432 11.9967L49.272 0H54.6214L51.243 9.56716H58.2634L61.6352 0H68.036L70.5674 7.70735H70.6662C71.081 5.98689 71.6017 4.29464 72.2253 2.64061L73.1514 0.00334918H77.9998L73.7711 12H67.6076L65.0103 3.81681H64.951C64.4599 5.68036 63.8747 7.51693 63.1975 9.31918L62.2483 12Z"
        mask="url(#Mask)"
        fill="#676560"
      />
    </svg>
  );
};

export default AnimatedLogo;
