import { useEffect, useState } from "react";

import { TechWorksPopup } from "@/components/TechWorksPopup";

interface UseTechWorksProps {
  id: string | null;
  endTime: string;
  forceShown?: null | boolean;
  setForceShown?(newValue: boolean): void;
}

enum STATUS_ENUM {
  INIT,
  SHOWN,
  HIDDEN,
}

const useTechWorks = ({
  id,
  endTime,
  forceShown = null,
  setForceShown,
}: UseTechWorksProps): null | JSX.Element => {
  if (!id) return null;

  const [status, setStatus] = useState(STATUS_ENUM.INIT);

  useEffect(() => {
    const neverShown = !window.localStorage.getItem(`tech_works_${id}`);
    if (neverShown && forceShown === null) {
      setStatus(STATUS_ENUM.SHOWN);
    } else {
      setStatus(STATUS_ENUM.HIDDEN);
    }
  }, []);

  useEffect(() => {
    if (forceShown && status === STATUS_ENUM.HIDDEN) {
      setStatus(STATUS_ENUM.SHOWN);
    }
  }, [forceShown]);

  const handleClose = () => {
    setStatus(STATUS_ENUM.HIDDEN);
    setForceShown && setForceShown(false);
  };

  return status === STATUS_ENUM.SHOWN ? (
    <TechWorksPopup id={id} close={handleClose} endTime={endTime} />
  ) : null;
};

export default useTechWorks;
