import { createPortal } from "react-dom";

interface ToastPortalProps {
  children?: React.ReactNode;
}

const ToastPortal = ({ children }: ToastPortalProps) => {
  const el = document.getElementById("toast-container") as Element;
  return createPortal(children, el);
};

export default ToastPortal;
