import { ToastBase } from "../ToastBase";
import { Toast } from "../ToastBase/types";
import styles from "./ToastIconMessage.module.scss";

interface ToastIconMessageProps {
  toast: Toast;
}

const ToastIconMessage = ({ toast }: ToastIconMessageProps) => {
  return (
    <ToastBase
      toast={toast}
      photoOrIconSlot={
        toast.photo && (
          <img className={styles.icon} src={toast.photo} alt="icon" />
        )
      }
    />
  );
};

export default ToastIconMessage;
