import { useRecoilValue } from "recoil";

import { BaseConfirmModal } from "../modals/BaseConfirmModal";
import { confirmationState } from "./confirmationState";

export const ConfirmationHub = () => {
  const confirmation = useRecoilValue(confirmationState);

  return (
    <BaseConfirmModal
      isOpen={!!confirmation}
      title={confirmation?.title}
      text={confirmation?.text}
      submitText={confirmation?.submitText}
      cancelText={confirmation?.cancelText}
      onSubmitButtonClickHandler={confirmation?.onSubmit}
      close={confirmation?.onCancel}
    />
  );
};
