import axios from "axios";
import { destroyCookie, parseCookies } from "nookies";

const AuthApi = {
  confirmCode: (phone: string, code: string) => {
    const cookie = parseCookies();
    const data = {
      phone,
      code,
      guest_token: cookie?.token,
    };
    return axios.post("/confirm", data);
  },

  smsAuth: (phone: string) => {
    const data = { phone };
    return axios.post("/auth-sms", data);
  },

  callAuth: (phone: string, magic: string) => {
    const data = { phone, magic };
    return axios.post("/auth-call", data);
  },

  logout: () => {
    destroyCookie(null, "token");
    destroyCookie(null, "client_id");
    destroyCookie(null, "role");
  },

  check: (client_id: string, token: string) =>
    axios.post(`/auth/check`, {
      client_id,
      token,
    }),

  registerGuest: () =>
    axios.post<{
      client_id: string;
      role: string;
      token: string;
    }>("/register-guest"),
};

export default AuthApi;
