import { MiniPopup } from "../MiniPopup";
import styles from "./TechWorksPopup.module.scss";

interface TechWorksPopupProps {
  close(): void;
  id: string;
  endTime: number | string;
}

const TechWorksPopup = ({ close, id, endTime }: TechWorksPopupProps) => {
  return (
    <MiniPopup
      shown={true}
      iconClass="svg-icon_tech-works"
      onCloseIcon={() => {
        close();
        window.localStorage.setItem(`tech_works_${id}`, "1");
      }}
      closeIcon={true}
      extraClass={styles.root}
    >
      <div>
        <div className={styles.title}>Дорогие друзья!</div>
        <div className={styles.content}>
          На сайте ведутся технические работы.
          <br />
          Вы сможете оформить и оплатить заказы {endTime}.<br />
          Приносим свои извинения за временные неудобства.
          <div className={styles.logo} />
        </div>
      </div>
    </MiniPopup>
  );
};

export default TechWorksPopup;
