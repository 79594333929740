import { parseCookies, setCookie } from "nookies";
import { useEffect } from "react";

import ReleaseApi from "@/api/ReleaseApi";

export const useReleaseControl = () => {
  useEffect(() => {
    document.addEventListener("visibilitychange", onWindowFocus);
  }, []);

  const setReleaseCookie = (version: string) => {
    setCookie(null, "release_version", version, {
      maxAge: 60 * 24 * 60 * 60,
      path: "/",
    });
  };

  const compareVersions = async () => {
    try {
      const response = await ReleaseApi.getVersion();
      const { release_version: version_cookies } = parseCookies();
      const version = response.data.version;
      if (!version_cookies) {
        setReleaseCookie(version);
      } else if (version !== version_cookies) {
        setReleaseCookie(version);
        window.location.reload();
      }
    } catch (e) {
      //
    }
  };

  const onWindowFocus = () => {
    if (document.visibilityState !== "visible") return;
    compareVersions();
  };
};
