import classNames from "classnames";
import Link from "next/link";

import { buttonVariants } from "./buttonVariants";
import styles from "./MainButton.module.scss";

export interface MainButtonProps {
  variant?: buttonVariants;
  children: React.ReactNode;
  className?: string;
  onClick?(): void;
  disabled?: boolean;
  href?: string;
  type?: "button" | "submit" | "reset";
}

const MainButton = ({
  variant = buttonVariants.WHITE,
  children,
  className = "",
  onClick,
  disabled = false,
  href = "",
  type,
}: MainButtonProps) => {
  if (href) {
    return (
      <Link
        className={classNames(styles.root, styles[variant], className)}
        href={href}
        onClick={onClick}
      >
        {children}
      </Link>
    );
  }
  return (
    <button
      className={classNames(styles.root, styles[variant], className)}
      onClick={onClick}
      disabled={disabled}
      type={type ? type : "button"}
    >
      {children}
    </button>
  );
};

export default MainButton;
